import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { theme } from '@primer/react';
import ThemeReferenceTree from '~/src/components/theme-reference-tree';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Colors in this theme object reference CSS variables from Primer Primitives under the hood with a raw value as a fallback. We recommend `}<a parentName="p" {...{
        "href": "primer/design/content/foundations/primitives/migrating.mdx"
      }}>{`using CSS variables directly`}</a>{` instead.`}</p>
    <ThemeReferenceTree themeData={theme} mdxType="ThemeReferenceTree" />
    <Note mdxType="Note">
      <p>{`See `}<a parentName="p" {...{
          "href": "/guides/react/theming"
        }}>{`Theming`}</a>{` to learn how theming works in Primer React.`}</p>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      